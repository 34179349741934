import { useEffect, useState } from "react";

// UTILS
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { capitalizeString } from "../../app/utils";
import { onUpdateActiveIndex } from "../../reducers/collectionReducer";
import {
  isCompanyWithoutShareholders,
  getCompanyData,
  scrollToElement,
  genericToastifyError,
} from "../../utils";
import {
  onUpdatePrimaryButton,
  onUpdateSecondaryButton,
} from "../../reducers/buttonsReducer";
import {
  addSendedStep,
  onUpdateCompletedStep,
  onUpdateFormData,
  onUpdateStep,
  onUpdateSubStep,
} from "../../reducers/onboardingDataReducer";
import { sendStep } from "../../connectors/connectors";
import useWindowWidth from "../../hooks/useWindowWidth";

// TYPES
import {
  Signatory as SignatoryType,
  StepEndpoint,
  StepFourPJ,
  StepThreePJ,
} from "../../types";

// COMPONENTS
import { Alert } from "@almafintech/react-components/Alert";
import { Button } from "@almafintech/react-components/Button";
import { showToastMessage } from "@almafintech/react-components/ToastMessage";
import Signatory from "./Signatory";

// ICONS
import AddCircle from "../../assets/images/icons/ui/add-circle-outline.svg?react";

import styles from "./Signatories.module.scss";
import AlertModal from "../AlertModal/AlertModal";

const Signatories = () => {
  const {
    signatoriesContainer,
    signatoriesList,
    addSignatoryContainer,
    disabledContainer,
    alertTitle,
  } = styles;

  const dispatch = useAppDispatch();

  const { formValues } = useAppSelector((state) => state.onboardingData);
  const signatories: SignatoryType[] = formValues?.signatories;

  const sendedSteps = useAppSelector(
    (state) => state.onboardingData.sendedSteps
  );

  const [buttonIsLoading, setButtonIsLoading] = useState(false);

  const dimensions = useWindowWidth();
  const isMobile = dimensions.width < 768;

  const emptySignatory: SignatoryType = {
    name: "",
    lastName: "",
    email: "",
    idNumber: "",
    confirmEmail: "",
  };

  const isUncompletedSignatory = (signatory: SignatoryType) =>
    !signatory || Object.values(signatory).some((value) => !value);

  const addNewSignatory = () => {
    const newSignatories = [...signatories, emptySignatory];
    dispatch(onUpdateFormData({ signatories: newSignatories }));
  };

  const handleAddSignatory = () => {
    if (!signatories) return;
    addNewSignatory();
    setTimeout(() => {
      const newSignatory = `signatory-${signatories?.length}`;
      scrollToElement(newSignatory);
    }, 200);
  };

  const isDisabledAddSignatory =
    !signatories ||
    signatories?.length === 3 ||
    signatories?.some(isUncompletedSignatory);

  const isDisabledPrimaryButton =
    !signatories || signatories?.some(isUncompletedSignatory);

  const onClickPrimaryButton = async () => {
    setButtonIsLoading(true);
    try {
      //Remove from sendedSteps the step with the same type. Prevents duplicates
      const filteredSendedSteps = sendedSteps.filter(
        (step: StepEndpoint) => step["@type"] !== "3_JURIDICAL"
      );
      const filteredSignatories = signatories?.filter(
        (signatory) => signatory !== null
      );
      const additionalSignatories = filteredSignatories
        .filter((signer) => signer.email !== formValues?.email)
        .map((signer) => ({
          name: signer.name,
          lastName: signer.lastName,
          email: signer.email,
          idNumber: signer.idNumber,
        }));

      const step: StepThreePJ = {
        "@type": "3_JURIDICAL",
        additionalSignatories,
      };
      await sendStep({
        stepId: 3,
        body: {
          companyType: getCompanyData().companyName,
          personType: "JURIDICAL", //Allway will be JURIDICAL
          email: formValues?.email || "",
          steps: [...filteredSendedSteps, step],
        },
      });

      const corporateType = formValues?.businessInformation?.corporateType;

      dispatch(addSendedStep(step));
      dispatch(onUpdateCompletedStep(5));

      if (isCompanyWithoutShareholders(corporateType)) {
        //Remove from sendedSteps the step with the same type. Prevents duplicates
        const filteredSendedSteps = sendedSteps.filter(
          (step: StepEndpoint) => step["@type"] !== "4_JURIDICAL"
        );

        const step: StepFourPJ = {
          "@type": "4_JURIDICAL",
          shareholders: [],
        };

        await sendStep({
          stepId: 4,
          body: {
            companyType: getCompanyData().companyName,
            personType: "JURIDICAL",
            email: formValues?.email || "",
            steps: [...filteredSendedSteps, step],
          },
        });

        dispatch(addSendedStep(step));
        dispatch(onUpdateStep(7));
        dispatch(onUpdateCompletedStep(6));
      } else {
        dispatch(onUpdateStep(6));
      }
    } catch (error) {
      showToastMessage(genericToastifyError, {
        containerId: "layoutPage",
        type: "error",
      });
      console.log(error);
      throw new Error("Error at signatories");
    } finally {
      setButtonIsLoading(false);
    }
  };

  // Initialize signatories
  useEffect(() => {
    // Since it's a joint signature, an empty signatory is added to complete
    if (!signatories) {
      const newSignatories: SignatoryType[] = [];
      const firstSignatory = {
        name: formValues?.name,
        lastName: capitalizeString(formValues?.lastName),
        email: formValues?.email,
        idNumber: formValues?.identityDocument,
        confirmEmail: formValues?.email,
      };
      newSignatories.push(firstSignatory);
      dispatch(onUpdateFormData({ signatories: newSignatories }));
      handleAddSignatory();
    }

    dispatch(
      onUpdateSecondaryButton({
        text: "Volver",
        onClick: () => dispatch(onUpdateSubStep(2)),
        show: true,
      })
    );
  }, []);

  // Handle primary button
  useEffect(() => {
    dispatch(
      onUpdatePrimaryButton({
        text: "Continuar",
        disabled: isDisabledPrimaryButton,
        isLoading: buttonIsLoading,
        onClick: onClickPrimaryButton,
        show: true,
      })
    );
  }, [buttonIsLoading, signatories]);

  // If a new signatory is added, set it as the active signatory
  useEffect(() => {
    if (
      signatories &&
      signatories?.length === 3 &&
      !signatories?.some(isUncompletedSignatory)
    ) {
      dispatch(onUpdateActiveIndex(null));
    } else {
      dispatch(onUpdateActiveIndex(signatories?.length));
    }
  }, [signatories]);

  // Add an empty signatory if there is only one
  useEffect(() => {
    if (!signatories) return;
    if (signatories?.length === 1) addNewSignatory();
  }, [signatories]);

  return (
    <>
      <AlertModal
        id="delete-signatory-preconfirmation"
        title="¿Quitar firmante?"
        message="Se eliminarán los datos ingresados. Esta acción no se puede deshacer."
      />
      <section className={signatoriesContainer}>
        <Alert variant="INFO" className="mb-4">
          <p className={alertTitle}>Agregá hasta 3 personas.</p>
          Cada persona recibirá un mail para finalizar el proceso de apertura de
          la cuenta
        </Alert>
        <div className={signatoriesList}>
          {signatories?.map((signatory, i) => (
            <Signatory
              key={`signatory-${i + 1}`}
              signatory={signatory}
              index={i + 1}
              onClick={() => {
                if (i !== 0) dispatch(onUpdateActiveIndex(i + 1));
              }}
            />
          ))}
          {isMobile && (
            <div
              className={`${addSignatoryContainer} ${
                isDisabledAddSignatory ? disabledContainer : ""
              }`}
              onClick={isDisabledAddSignatory ? () => null : handleAddSignatory}
            >
              <AddCircle />
            </div>
          )}
        </div>
        {!isMobile && signatories?.length < 3 && (
          <Button
            className="m-auto"
            text="Agregar nuevo firmante"
            onClick={handleAddSignatory}
            disabled={isDisabledAddSignatory}
          />
        )}
      </section>
    </>
  );
};

export default Signatories;
