import { useState } from "react";
import { useNavigate } from "react-router-dom";
import type {
  InputType,
  PreconfirmationField,
  PreconfirmationStep,
} from "../../../types";
import { Option } from "../../../types";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { onUpdatePreconfirmation } from "../../../reducers/preconfirmationReducer";
import {
  onUpdateStep,
  onUpdateSubStep,
} from "../../../reducers/onboardingDataReducer";
import { Button } from "@almafintech/react-components/Button";
import useWindowWidth from "../../../hooks/useWindowWidth";
import { FormikValues } from "formik";

import EditIcon from "../../../assets/images/icons/ui/edit.svg?react";
import Arrow from "../../../assets/images/icons/ui/arrow.svg?react";

import styles from "./PreconfirmationStep.module.scss";

const PreconfirmationStep = ({
  name,
  columns,
  fields,
  fieldsWithSeparator,
  editStep,
}: PreconfirmationStep) => {
  const {
    stepContainer,
    header,
    button,
    body,
    field,
    textArea,
    twoColumns,
    fourColumns,
    open,
  } = styles;

  const [openStep, setOpenStep] = useState<boolean>(false);

  const { formValues } = useAppSelector(({ onboardingData }) => onboardingData);

  const { onboardingType } = useAppSelector(
    ({ activeTemplate }) => activeTemplate
  );

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const handleEdit = ({
    step,
    substep,
  }: {
    step: number;
    substep?: number;
  }) => {
    const obType = () => {
      if (onboardingType === "PHYSICAL") return "/personal";
      else if (onboardingType === "JURIDICAL") return "/empresarial";
      else if (onboardingType === "SIGNATORY") return "/firmante";
      else return "/";
    };

    dispatch(onUpdatePreconfirmation(true));
    dispatch(onUpdateStep(Number(step)));
    substep && dispatch(onUpdateSubStep(substep));
    navigate(obType());
  };

  const conditionalFieldHasValue = (
    name: string,
    values: FormikValues | null = null
  ) => {
    if (!formValues) return false;

    const splittedName = name.split(".");
    const nestedValue = splittedName.reduce(
      (acc, key) => acc?.[key],
      values ?? formValues
    );

    return !!nestedValue;
  };

  const getValue = (
    name: string,
    type?: InputType,
    formatInPreConfirmation?: (value: string) => string,
    options?: Option[],
    values: FormikValues | null = null,
    valueGetter?: (values: FormikValues) => string,
    canUploadMultipleFiles?: boolean
  ) => {
    if (!formValues) return "-";

    if (valueGetter) return valueGetter(values ?? formValues);

    const splittedName = name.split(".");

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let nestedValue: any = splittedName.reduce(
      (acc, key) => acc?.[key],
      values ?? formValues
    );

    if (type === "file") {
      if (canUploadMultipleFiles) {
        const files = nestedValue;
        if (files && Array.isArray(files)) {
          return files?.map((f: File) => f.name).join(", ");
        } else {
          return "-";
        }
      } else {
        const file = nestedValue;
        return file ? file.name : "-";
      }
    }

    // Get the label of the selected options
    if (options) {
      const hasMultipleValues = Array.isArray(nestedValue);
      const selectedOptions = hasMultipleValues
        ? nestedValue?.flatMap((val: string) =>
            options?.filter((option) => option.value === val)
          )
        : options?.find((option) => option.value === nestedValue);

      nestedValue = hasMultipleValues
        ? selectedOptions?.map((option: Option) => option.label).join(", ")
        : selectedOptions?.label;
    }

    if (formatInPreConfirmation)
      nestedValue = formatInPreConfirmation(nestedValue);

    return nestedValue || "-";
  };

  const dimensions = useWindowWidth();
  const isMobile = dimensions.width < 768;

  const StepBody = (fields: PreconfirmationField[]) => {
    return (
      <div className={`${body} ${columns === 2 ? twoColumns : fourColumns}`}>
        {fields?.map(
          ({
            name,
            label,
            type,
            options,
            isConditional,
            formatInPreConfirmation,
            values,
            valueGetter,
            canUploadMultipleFiles,
          }) => {
            if (!isConditional || conditionalFieldHasValue(name, values))
              return (
                <div
                  key={name}
                  className={`${field} ${
                    columns === 4 && type === "textarea" ? textArea : ""
                  }`}
                >
                  <span>{label}</span>
                  <span>
                    {getValue(
                      name,
                      type,
                      formatInPreConfirmation,
                      options,
                      values,
                      valueGetter,
                      canUploadMultipleFiles
                    )}
                  </span>
                </div>
              );
          }
        )}
      </div>
    );
  };

  return (
    <section className={stepContainer}>
      <div className={header}>
        <div onClick={() => setOpenStep((prev) => !prev)}>
          <h3>{name}</h3>
          {isMobile && <Arrow className={`${openStep ? open : ""}`} />}
        </div>
        {editStep && (
          <Button
            text="Editar"
            variant="tertiary"
            onClick={() => handleEdit(editStep)}
            className={button}
            endContent={<EditIcon />}
          />
        )}
      </div>
      {(!isMobile || openStep) && (
        <>
          {!fieldsWithSeparator && StepBody(fields || [])}
          {fieldsWithSeparator?.map((fields, i) => {
            return (
              <>
                {StepBody(fields)}
                {i !== fieldsWithSeparator.length - 1 && <hr />}
              </>
            );
          })}
        </>
      )}
    </section>
  );
};

export default PreconfirmationStep;
