import React, { CSSProperties, useEffect, useRef, useState } from "react";
import { BasicStep, Step } from "../../types";
import { ToastMessage } from "@almafintech/react-components/ToastMessage";
import DotStep from "../DotStep/DotStep";
import useWindowWidth from "../../hooks/useWindowWidth";

import styles from "./FormContainer.module.scss";

interface FormContainerProps {
  currentStep?: Step;
  children: React.ReactNode;
  currentSubStep: number;
  icon?: string;
  className?: string;
  classNameFormContainer?: string;
  hideHeader?: boolean;
}

const FormContainer = ({
  currentStep,
  currentSubStep,
  children,
  icon,
  className,
  classNameFormContainer,
  hideHeader,
}: FormContainerProps) => {
  const {
    formContainer,
    infoSection,
    header,
    headerContainer,
    childrenStyle,
    modalAlert,
    overflowScrollGradient,
  } = styles;

  const hasSubSteps = !!currentStep?.subSteps;
  const subtitle =
    currentStep?.subtitle ||
    (currentStep?.subSteps?.find((s) => s.step === currentSubStep) as BasicStep)
      ?.subtitle;

  const title =
    (currentStep?.subSteps?.find((s) => s.step === currentSubStep) as BasicStep)
      ?.titleOfStep || currentStep?.name;

  const dimensions = useWindowWidth();
  const isMobile = dimensions.width < 768;

  const [showOverflowGradient, setShowOverflowGradient] = useState(false);

  const headerRef = useRef<HTMLDivElement>(null);

  const [headerHeight, setHeaderHeight] = useState(0);

  useEffect(() => {
    if (headerRef.current) {
      setHeaderHeight(
        Number.parseInt(getComputedStyle(headerRef.current).height)
      );
    }
  }, [headerRef.current]);

  const totalEnableSpace = (window.innerHeight * 90) / 100;
  const maxHeight = Math.round(totalEnableSpace - headerHeight);

  const getWrapperStyles = (): CSSProperties => {
    if (headerHeight) {
      return {
        maxHeight: `${maxHeight}px`,
        marginTop: "0.7rem",
        marginBottom: "0.7rem",
      };
    }

    return {
      height: "100%",
      marginTop: "1rem",
    };
  };

  const getOverflowScrollStyles = (): CSSProperties => ({
    position: "relative",
    maxHeight: getWrapperStyles().maxHeight || "100%",
  });

  const handleScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const node = event.target as HTMLInputElement;
    const reachedTop = node.scrollTop === 0;

    if (reachedTop) setShowOverflowGradient(false);
    else if (!showOverflowGradient) setShowOverflowGradient(true);
  };

  return (
    <div className={`${formContainer} ${classNameFormContainer}`}>
      <ToastMessage
        width="100%"
        messageId="formAlert"
        position="top-center"
        className={modalAlert}
      />
      {!hideHeader && (
        <div className={headerContainer}>
          <div className={header}>
            <div className={infoSection}>
              {icon && !isMobile && <img src={icon} />}
              <h1>{title} </h1>
            </div>
            <h3>{subtitle}</h3>
          </div>
          <div>
            {hasSubSteps && (
              <DotStep
                currentSubStep={currentSubStep}
                subSteps={currentStep?.subSteps}
              />
            )}
          </div>
        </div>
      )}
      {!isMobile ? (
        <div
          className={`${showOverflowGradient ? overflowScrollGradient : ""}`}
          style={getOverflowScrollStyles()}
        >
          <div
            className={`${childrenStyle} ${className} ${classNameFormContainer}`}
            onScroll={handleScroll}
          >
            {children}
          </div>
        </div>
      ) : (
        <div
          className={`${childrenStyle} ${className} ${classNameFormContainer}`}
        >
          {children}
        </div>
      )}
    </div>
  );
};

export default FormContainer;
