import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  onUpdatePrimaryButtonModal,
  openModal,
} from "../../reducers/modalReducer";
import { ShareholderData } from "../../types";
import { RoundedButton } from "@almafintech/react-components";
import { onUpdateFormData } from "../../reducers/onboardingDataReducer";
import { onUpdateActiveIndex } from "../../reducers/collectionReducer";
import AlertModal from "../AlertModal/AlertModal";

import DeleteIcon from "../../assets/images/icons/ui/delete.svg?react";
import PhysicalPerson from "../../assets/images/icons/ui/body-dark.svg?react";
import JuridicalPerson from "../../assets/images/icons/ui/juridical-person.svg?react";

import styles from "./Shareholders.module.scss";

interface ShareHolderProps {
  shareholder: ShareholderData;
  index: number;
  onClick?: () => void;
  showDeletePreconfirmation: boolean;
}

const Shareholder = ({
  shareholder,
  index,
  onClick,
  showDeletePreconfirmation,
}: ShareHolderProps) => {
  const { shareholderContainer, ownershipContainer, active, icon } = styles;

  const dispatch = useAppDispatch();

  const { formValues } = useAppSelector((state) => state.onboardingData);
  const { activeIndex, isEditing } = useAppSelector(
    (state) => state.collectionData
  );

  const shareholders: ShareholderData[] = formValues?.shareholders;

  const isActive = activeIndex === index;

  const isPhysicalPerson = (shareholder: ShareholderData) =>
    shareholder?.personType === "PHYSICAL";

  const removeShareholder = () => {
    if (!activeIndex) return;
    const newShareholders = [...shareholders];
    newShareholders.splice(index - 1, 1);
    if (activeIndex !== 1) dispatch(onUpdateActiveIndex(activeIndex - 1));
    dispatch(onUpdateFormData({ shareholders: newShareholders }));
  };

  useEffect(() => {
    dispatch(
      onUpdatePrimaryButtonModal({
        text: "Sí, quitar",
        action: removeShareholder,
      })
    );
  }, []);

  return (
    <>
      <AlertModal
        id="delete-shareholder-preconfirmation"
        title="¿Quitar accionista?"
        message="Se descartarán sus datos."
      />
      <div
        id={`shareholder-${index}`}
        className={`${shareholderContainer} ${isActive && active}`}
        onClick={onClick}
      >
        <div className={ownershipContainer}>
          {isPhysicalPerson(shareholder) ? (
            <PhysicalPerson />
          ) : (
            <JuridicalPerson width={13} height={12} />
          )}
          {shareholder?.ownership !== 0 && (
            <span>{shareholder?.ownership}%</span>
          )}
        </div>
        <div>
          <h3>Accionista {index}</h3>
          {isPhysicalPerson(shareholder) ? (
            <p>
              {shareholder.name !== "" ? shareholder.name : <span>Nombre</span>}{" "}
              {shareholder.lastName !== "" ? (
                shareholder.lastName
              ) : (
                <span>Apellido</span>
              )}
            </p>
          ) : (
            <p>
              {shareholder?.legalName !== "" ? (
                shareholder?.legalName
              ) : (
                <span>Razón social</span>
              )}
            </p>
          )}
        </div>
        <RoundedButton
          icon={<DeleteIcon />}
          buttonType="empty"
          className={icon}
          onClick={
            isEditing || showDeletePreconfirmation
              ? (e) => {
                  e.preventDefault();
                  dispatch(
                    openModal({ name: "delete-shareholder-preconfirmation" })
                  ) &&
                    dispatch(
                      onUpdatePrimaryButtonModal({
                        text: "Sí, eliminar",
                        action: removeShareholder,
                      })
                    );
                }
              : () => removeShareholder()
          }
        ></RoundedButton>
      </div>
    </>
  );
};

export default Shareholder;
