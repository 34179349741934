import successIcon from "../../assets/images/icons/ui/ui-icon-success.svg";

import styles from "./Signatories.module.scss";

const SignatoriesSuccess = () => {
  const { successContainer } = styles;
  return (
    <section className={successContainer}>
      <img src={successIcon} alt="success" />
      <h1>Firmantes guardados</h1>
      <p>¡Listo! Llegaste al máximo de firmantes.</p>
      <p>
        Podés editar la información si necesitás hacer cambios o continuar con
        el registro.
      </p>
    </section>
  );
};

export default SignatoriesSuccess;
