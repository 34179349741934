import * as Sentry from "@sentry/react";
import { FormikValues } from "formik";
import UploadDniCard from "../../components/UploadDniCard/UploadDniCard";
import LivenessValidationCard from "../../components/LivenessValidationCard/LivenessValidationCard";
import {
  getCountryList,
  getDraft,
  getPhysicalActivities,
  sendStep,
  validateRenaper,
} from "../../connectors/connectors";
import {
  cuitRequired,
  radioOrSingleSelectRequired,
  smallStringRequired,
  dniRequired,
  tramitNumberRequired,
  phone,
  largeStringRequired,
  mediumStringRequired,
  multipleSelectRequired,
  smallString,
  zipCodeRequired,
  checkboxRequired,
  checkbox,
  cuitSpouse,
} from "../../validations";
import {
  CivilStatus,
  Step,
  Template,
  Option,
  PitbullActivities,
  PitbullCountries,
  ComponentStep,
  PersonType,
  IdentityType,
  IdentityVersion,
  GenderInitials,
  PreconfirmationStep,
  StepEndpoint,
  StepOnePF,
  PitbullCountry,
  PitbullActivityPhysical,
  GetDraftResponse,
  StepFourPJ,
  Representative,
  StepSevenPJ,
  StepSixPJ,
  StepTwoPJ,
  PreconfirmationField,
  PitbullProvinces,
} from "../../types";
import bodyIcon from "../../assets/images/icons/ui/body.svg";
import {
  capitalizeString,
  fuseProvince,
  getProvincesByIso,
  parseBirthDate,
  parseDate,
  parseGenderFromInitial,
  parseRegistrationAuthority,
  parseTelephone,
} from "../../app/utils";
import TokenStep from "../../components/TokenStep";
import { updateRenaperData } from "../../reducers/renaperReducer";
import {
  addSendedStep,
  onUpdateCompletedStep,
  onUpdateDraftUUID,
  onUpdateFormData,
  onUpdateReachedStep,
  onUpdateStep,
  onUpdateSubStep,
} from "../../reducers/onboardingDataReducer";
import { addOneValidation } from "../../reducers/validationsReducer";
import {
  asCuit,
  asDni,
  genericToastifyError,
  getCompanyData,
  getOnboardingFields,
  getPreconfirmationStepFieldsByName,
  parseStateAddress,
} from "../../utils";
import { logAnalyticsAndHotjarEvent } from "../../main";
import SignatoryLanding from "../../components/SignatoryLanding/SignatoryLanding";
import InteractiveInformation from "../../components/InteractiveInformation/InteractiveInformation";
import { showToastMessage } from "@almafintech/react-components/ToastMessage";
import { onUpdateInfoStep } from "../../reducers/infoStepReducer";
import { deleteDoc, doc, getFirestore } from "firebase/firestore";
import {
  updateSignatureRequestId,
  updateToken,
} from "../../reducers/authReducer";
import onboardingJuridical from "./juridical";

const genderOptions = [
  { value: "M", label: "Masculino" },
  { value: "F", label: "Femenino" },
  { value: "X", label: "Otro" },
];

const pepOptions = [
  { value: "DIRECT", label: "Sí, directamente" },
  { value: "FAMILY", label: "Sí, por vínculo familiar" },
  { value: "NONE", label: "No" },
];

const civilStatusOptions: { value: CivilStatus; label: string }[] = [
  { value: "SINGLE", label: "Soltero/a" },
  { value: "MARRIED", label: "Casado/a" },
  { value: "WIDOWED", label: "Viudo/a" },
  { value: "DIVORCED", label: "Divorciado/a" },
];

const getActivities = async (): Promise<PitbullActivities> => {
  try {
    const { data } = await getPhysicalActivities();
    return data;
  } catch (error) {
    throw new Error("Error al obtener las actividades");
  }
};

const getCountries = async (): Promise<PitbullCountries> => {
  try {
    const { data } = await getCountryList();
    return data;
  } catch (error) {
    throw new Error("Error al obtener los países");
  }
};

const stepOne: ComponentStep = {
  step: 1,
  name: "Send",
  hideStepper: true,
  component: (dispatch, state, formik) => {
    if (!state || !dispatch || !formik)
      throw new Error("Error: state, dispatch or formik not found");

    return <SignatoryLanding />;
  },
  disableBackNavigation: true,
};

const stepTwo: ComponentStep = {
  step: 2,
  name: "Mail",
  hideStepper: true,
  component: (dispatch, state, formik) => {
    if (!state || !dispatch || !formik)
      throw new Error("Error: state, dispatch or formik not found");

    const completeSteps = (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      steps: any,
      representative?: Representative
    ) => {
      dispatch(
        onUpdateFormData({
          onboardingPJ: steps,
        })
      );

      if (representative) {
        const {
          representativeInformation: {
            cuit,
            gender,
            pep,
            pepDetails,
            telephone,
            identityDocument,
            addresses,
            spouse,
            ...rest
          },
        } = representative;

        const parsedPep = pep ?? "NONE";
        const parsePepDetails =
          parsedPep && parsedPep !== "NONE" ? pepDetails : undefined;
        const parsedGender =
          gender === "MALE" ? "M" : gender === "FEMALE" ? "F" : "X";

        const spouseData = {
          spouseName: spouse?.name,
          spouseLastName: spouse?.lastName,
          spouseCuil: spouse?.cuit,
        };

        const addressesData = {
          country: addresses[0].country,
          fullAddress: `${addresses[0].street} ${addresses[0].number} ${addresses[0].city}`,
          zipCode: addresses[0].zipCode,
          state: addresses[0].state,
          municipality: addresses[0].city,
          floor: addresses[0].floor,
          apartment: addresses[0].apartment,
        };

        const representativeParsed = {
          gender: parsedGender,
          pep: parsedPep,
          pepDetails: parsePepDetails,
          telephone: parseTelephone(telephone),
          identityDocument: identityDocument?.idNumber || cuit.slice(2, 10),
          tramitNumber: identityDocument?.procedureId,
          ...(addresses && addresses.length > 0 && addressesData),
          ...(spouse && spouseData),
          ...rest,
        };

        formik?.setValues(
          (prevValues) => ({
            ...prevValues,
            ...representativeParsed,
          }),
          false
        );
        dispatch(onUpdateFormData(representativeParsed));
      }
    };

    const onAuthorize = async (draftUUID?: string) => {
      try {
        const params = new URLSearchParams(document.location.search);
        const email = params.get("email");

        if (!draftUUID || !state || !formik || !email)
          throw new Error("DraftUUID or state not found");

        try {
          const draftUUID = state.onboardingData.draftUUID;
          const draftResponse = await getDraft({
            draftUUID,
          });

          const onboardingSteps = draftResponse.data.steps;
          const stepSeven: StepSevenPJ = onboardingSteps.find(
            (step: StepSevenPJ) => step.representatives
          );

          const representative = stepSeven.representatives.find(
            (representative) =>
              representative.representativeInformation.email === email
          );

          completeSteps(onboardingSteps, representative);

          if (representative) {
            dispatch(onUpdateInfoStep("PENDING_REGISTRY_REMOTE"));
          }
        } catch (error) {
          console.log("Error on getDraft", error);
        }
      } catch (error) {
        console.log(error, "Error on authorize");
        Sentry.captureException(error);
      }
    };

    const onSuccess = async () => {
      const { type } = state.infoStep;
      if (type !== null) {
        dispatch(onUpdateStep("information-step"));
      } else {
        dispatch(onUpdateCompletedStep(2));
        dispatch(onUpdateReachedStep(3));
        dispatch(onUpdateStep(3));
      }
    };

    return (
      <TokenStep
        previousStep={1}
        onSuccess={onSuccess}
        onAuthorize={onAuthorize}
        formik={formik}
      />
    );
  },
  disableBackNavigation: true,
};

const stepThree = (
  countries: PitbullCountry[],
  countriesOptions: Option[],
  activitiesOptions: Option[],
  getProvincesFilteredByCountry: (country?: string) => PitbullProvinces,
  provinces: PitbullProvinces
): Step => {
  const provincesOption = provinces.map((prov) => ({
    value: prov.name,
    label: prov.name,
  }));
  return {
    step: 3,
    name: "Datos personales",
    hideStepper: true,
    subSteps: [
      {
        step: 1,
        fields: [
          {
            placeholder: "XX.XXX.XXX",
            initialValue: "",
            type: "dni",
            name: "identityDocument",
            label: "DNI",
            validation: dniRequired,
            formatInPreConfirmation: (value) => asDni(value ? value : "-"),
          },
          {
            placeholder: "XXXXXXXXXXX",
            initialValue: "",
            type: "text",
            name: "tramitNumber",
            label: "Número de trámite",
            tooltip: {
              label: "¿Dónde lo encuentro?",
              modal: "modal-tramit-number",
            },
            validation: tramitNumberRequired,
          },
          {
            name: "gender",
            type: "multiple-radio",
            placeholder: "Seleccioná una opción",
            validation: radioOrSingleSelectRequired(genderOptions),
            initialValue: "",
            options: genderOptions,
            label: "Género",
          },
        ],
        onContinue: async (values: FormikValues, dispatch, state) => {
          try {
            if (!state) throw new Error("Error reading state at stepThree");

            const { gender, tramitNumber, identityDocument } = values;
            const { data } = await validateRenaper({
              gender,
              order: tramitNumber,
              number: identityDocument,
            });

            const failedStatus = ["FAILED", "CHECK"];

            // TYPES OF STATUS "OK" | "FAILED" | "CHECK"
            if (!data || !data.status || failedStatus.includes(data.status))
              throw new Error("FAILED at validate Renaper");
            else if (data.status === "OK" && dispatch) {
              const information = data.validations[0].information;

              // Convert string to JSON
              const informationParsed = JSON.parse(information);
              dispatch(
                updateRenaperData({
                  ...informationParsed,
                  externalCode: data.validations[0].externalCode,
                })
              );
              dispatch(
                addOneValidation({
                  name: "RENAPER",
                  externalCode: data.validations[0].externalCode,
                })
              );
              const { isExternalInformation } = state.onboardingData;
              const {
                names,
                lastNames,
                birthdate,
                countryBirth,
                streetAddress,
                numberStreet,
                country,
                city,
                nationality,
                municipality,
              } = informationParsed;

              const parsedCountry = countries.find(({ name }) => {
                const lowerCaseName = name.toLowerCase();
                if (countryBirth)
                  return countryBirth?.toLowerCase() === lowerCaseName;
                else return country?.toLowerCase() === lowerCaseName;
              })?.name;

              const parsedCitizenship = countries.find(({ citizenship }) => {
                const lowerCaseCitizenship = citizenship?.toLowerCase();

                return nationality?.toLowerCase() === lowerCaseCitizenship;
              })?.citizenship;

              const getAddress = () => {
                let cityParsed = city;
                let municipalityParsed = municipality;
                if (city === "SIN INFORMAR") cityParsed = "";
                if (municipality === "SIN INFORMAR") municipalityParsed = "";

                if (!isExternalInformation) {
                  return {
                    birthCountry: parsedCountry,
                    addresses: {
                      fullAddress: `${streetAddress} ${numberStreet} ${cityParsed} ${municipalityParsed}`,
                      country: parsedCountry,
                    },
                  };
                }
              };
              const body = {
                name: names,
                lastName: lastNames,
                birthDate: birthdate,
                citizenship: parsedCitizenship,
                ...getAddress(),
              };
              dispatch(onUpdateFormData({ ...body }));
              return { newValuesToForm: body };
            }
          } catch (error) {
            console.log(error, "error en el try de renaper");
            Sentry.captureException(error);
            throw new Error("Error al validar renaper");
          }
        },
      },
      {
        step: 2,
        label: "uploadDNI",
        subtitle: "Cargá fotos del frente y dorso de tu DNI.",
        component: (dispatch, state, formik) => {
          return (
            <UploadDniCard dispatch={dispatch} state={state} formik={formik} />
          );
        },
        insideForm: true,
      },
      {
        step: 3,
        component: (dispatch, state, formik) => {
          return (
            <LivenessValidationCard
              dispatch={dispatch}
              state={state}
              formik={formik}
            />
          );
        },
        insideForm: true,
      },
      {
        step: 4,
        disableButtonBack: true,
        fields: [
          {
            name: "name",
            type: "text",
            label: "Nombre/s",
            validation: smallStringRequired,
            initialValue: "",
            columns: 2,
            disabled: true,
          },
          {
            name: "lastName",
            type: "text",
            label: "Apellido/s",
            validation: smallStringRequired,
            initialValue: "",
            columns: 2,
            disabled: true,
            formatInPreConfirmation: (value) => capitalizeString(value),
          },
          {
            name: "citizenship",
            type: "text",
            label: "Nacionalidad",
            validation: smallStringRequired,
            initialValue: "",
            columns: 2,
            disabled: true,
            formatInPreConfirmation: (value) => capitalizeString(value),
          },
          {
            name: "birthDate",
            type: "text",
            label: "Fecha de nacimiento",
            validation: smallStringRequired,
            initialValue: "",
            columns: 2,
            disabled: true,
          },
          {
            name: "civilStatus",
            type: "select",
            label: "Estado civil",
            placeholder: "Seleccioná una opción",
            validation: radioOrSingleSelectRequired(civilStatusOptions),
            options: civilStatusOptions,
            selectType: "single",
            initialValue: "",
          },
          {
            name: "spouseName",
            type: "text",
            label: "Nombre/s del cónyuge",
            validation: smallStringRequired,
            initialValue: "",
            columns: 2,
            conditional: {
              name: "civilStatus",
              value: "MARRIED",
            },
          },
          {
            name: "spouseLastName",
            type: "text",
            label: "Apellido/s del cónyuge",
            validation: smallStringRequired,
            initialValue: "",
            columns: 2,
            conditional: {
              name: "civilStatus",
              value: "MARRIED",
            },
          },
          {
            name: "spouseCuil",
            type: "cuit",
            label: "CUIL del cónyuge",
            validation: cuitSpouse,
            initialValue: "",
            placeholder: "XX-XXXXXXXX-X",
            conditional: {
              name: "civilStatus",
              value: "MARRIED",
            },
          },
          {
            name: "telephone",
            type: "phone",
            label: "Teléfono celular",
            validation: phone,
            initialValue: "",
            infoText: "Con código de área sin 0 ni 15.",
            formatInPreConfirmation: (value) =>
              value.startsWith("+") ? value : `+${value}`,
          },
          {
            name: "activities",
            type: "select",
            label: "Rubro o actividad",
            placeholder: "Seleccioná las actividades que correspondan",
            validation: multipleSelectRequired(activitiesOptions),
            options: activitiesOptions,
            selectType: "multiple-checkbox",
            initialValue: "",
            showExternalBox: true,
          },
        ],
      },
      {
        step: 5,
        fields: [
          {
            name: "addresses.country",
            type: "select",
            label: "País de residencia",
            placeholder: "Seleccioná una opción",
            validation: radioOrSingleSelectRequired(countriesOptions),
            options: countriesOptions,
            selectType: "single",
            initialValue: "",
            onChange: (_, setFieldValue) => {
              const fieldsToClear = {
                "addresses.fullAddress": "",
                "addresses.municipality": "",
                "addresses.stateSelect": "",
                "addresses.stateText": "",
                "addresses.state": "",
                "addresses.zipCode": "",
                "addresses.floor": "",
                "addresses.apartment": "",
              };

              Object.entries(fieldsToClear).forEach(([field, value]) => {
                setFieldValue(field, value);
              });
            },
          },
          {
            name: "addresses.fullAddress",
            type: "address",
            label: "Domicilio",
            validation: mediumStringRequired,
            initialValue: "",
            autoSelect: "addresses.fullAddress",
            country: "addresses.country",
            onChange: (values, setFieldValue) => {
              const {
                formattedAddress: {
                  postalCode: zipCode,
                  state,
                  city,
                  municipality,
                  street,
                  number,
                  country,
                },
              } = values;

              const provinces = getProvincesFilteredByCountry(country);

              const fusedProvince = fuseProvince(provinces, state, city);

              //// If no records are found in FUSE, we set the first province by default to avoid breaking
              const finalProvince = fusedProvince || provinces[0].name;

              setFieldValue("addresses.zipCode", zipCode);
              setFieldValue("addresses.state", finalProvince);
              setFieldValue("addresses.city", city);
              setFieldValue("addresses.municipality", municipality);
              setFieldValue("addresses.street", street);
              setFieldValue("addresses.number", number);
            },
            autoComplete: "off",
            formatInPreConfirmation: (value) =>
              value?.split(",")?.[0].replace("null", ""),
          },
          {
            name: "addresses.floor",
            type: "text",
            label: "Piso",
            validation: smallString,
            initialValue: "",
            columns: 3,
            maxLength: 10,
          },
          {
            name: "addresses.apartment",
            type: "text",
            label: "Departamento",
            validation: smallString,
            initialValue: "",
            columns: 3,
            maxLength: 10,
          },
          {
            name: "addresses.zipCode",
            type: "text",
            label: "Código postal",
            validation: zipCodeRequired,
            initialValue: "",
            columns: 3,
          },
          {
            name: "addresses.municipality",
            type: "text",
            label: "Localidad",
            validation: smallStringRequired,
            initialValue: "",
            columns: 2,
          },
          {
            name: "addresses.state",
            type: "select",
            label: "Provincia",
            validation: radioOrSingleSelectRequired(provincesOption),
            initialValue: "",
            dynamicOptions: (formik) => {
              const value = formik.values.addresses.country;

              //Filter provinces by country
              const provinces = getProvincesFilteredByCountry(
                value || undefined
              );

              const provincesOption = provinces.map((prov) => ({
                value: prov.name,
                label: prov.name,
              }));

              return provincesOption;
            },
            columns: 2,
          },
          {
            name: "pep",
            type: "select",
            label: "¿Estás expuesto/a políticamente?",
            tooltip: {
              label: "¿Qué significa?",
              modal: "modal-PEP",
            },
            placeholder: "Seleccioná una opción",
            validation: radioOrSingleSelectRequired(pepOptions),
            options: pepOptions,
            selectType: "single",
            initialValue: "",
            preconfirmationLabel: "¿Expuesto/a políticamente?",
          },
          {
            conditional: {
              name: "pep",
              value: ["DIRECT", "FAMILY"],
            },
            name: "pepDetails",
            type: "textarea",
            label: "Describí brevemente la situación",
            validation: largeStringRequired,
            initialValue: "",
          },
        ],
        onContinue: async (values: FormikValues, dispatch, state) => {
          if (!state || !state.validations || !dispatch)
            throw new Error("Error: state or dispatch not found");

          const draftUUID = state.onboardingData?.draftUUID;

          let draft: GetDraftResponse;

          try {
            const { data } = await getDraft({
              draftUUID,
            });

            draft = data;
          } catch (error) {
            throw new Error("Error al obtener el draft");
          }

          const stepsWithType = draft.steps.map((step, index) => {
            if ("shareholders" in step) {
              const shareHolderStep = step as StepFourPJ;

              return {
                "@type": `${index + 1}_JURIDICAL`,
                ...step,
                shareholders: shareHolderStep.shareholders.map(
                  (shareholder) => {
                    return {
                      "@type": shareholder.personType,
                      ...shareholder,
                    };
                  }
                ),
              };
            } else {
              return {
                "@type": `${index + 1}_JURIDICAL`,
                ...step,
              };
            }
          });

          try {
            const {
              name,
              lastName,
              gender,
              identityDocument,
              tramitNumber,
              citizenship,
              civilStatus,
              telephone,
              birthDate,
              birthCountry,
              pep,
              addresses: {
                floor,
                apartment,
                state: stateAddress,
                city,
                street,
                number,
                municipality,
                country,
                zipCode,
              },
              spouseCuil,
              spouseLastName,
              spouseName,
              activities,
            } = values;

            const email = state?.onboardingData?.formValues?.email;

            const stepOne = state.onboardingData?.sendedSteps?.find(
              (step) => "personalInformation" in step
            ) as StepOnePF;

            const versionOfIdentity =
              state.renaper.copy ||
              stepOne.personalInformation.identityDocument.version;

            const creationDate = state.renaper.creationDate
              ? parseDate(state.renaper.creationDate)
              : stepOne.personalInformation.identityDocument.creationDate;

            const expirationDate = state.renaper.expirationDate
              ? parseDate(state.renaper.expirationDate)
              : stepOne.personalInformation.identityDocument.expirationDate;

            const step = {
              "@type": "7_JURIDICAL",
              representatives: [
                {
                  "@type": "1_JURIDICAL",
                  referrer: "",
                  representativeInformation: {
                    activities,
                    name,
                    lastName,
                    cuit: state.renaper.cuil || "",
                    email,
                    gender: parseGenderFromInitial[gender as GenderInitials],
                    identityDocument: {
                      type: "DNI" as IdentityType,
                      idNumber: identityDocument,
                      procedureId: tramitNumber,
                      version: versionOfIdentity as IdentityVersion,
                      creationDate,
                      expirationDate,
                    },
                    telephone: `+${telephone}`,
                    citizenship,
                    birthCountry,
                    birthDate: parseDate(birthDate),
                    civilStatus,
                    pep,
                    pepDetails: pep === "NONE" ? undefined : values.pepDetails,
                    addresses: [
                      {
                        street,
                        number,
                        zipCode,
                        city,
                        state: parseStateAddress(stateAddress) || "",
                        country,
                        floor,
                        apartment,
                        municipality,
                      },
                    ],
                    spouse:
                      civilStatus === "MARRIED"
                        ? {
                            ...(spouseCuil && { cuit: spouseCuil }),
                            lastName: spouseLastName,
                            name: spouseName,
                          }
                        : undefined,
                  },
                  validations: state.validations,
                },
              ],
            };

            //Remove from sendedSteps the step with the same type. Prevents duplicates
            const filteredSendedSteps = (stepToFilter: string) =>
              stepsWithType.filter(
                (step: StepEndpoint) => step["@type"] !== stepToFilter
              );

            const body = {
              companyType: getCompanyData().companyName,
              personType: "JURIDICAL" as PersonType,
              email: draft.email,
              steps: [...filteredSendedSteps("7_JURIDICAL"), step],
            };

            await sendStep({ stepId: 7, body });
            dispatch(addSendedStep(step));
          } catch (error) {
            console.log("Error step four: ", error);
            throw new Error("Error al enviar los datos en el paso 4");
          }
        },
      },
    ],
    disableBackNavigation: true,
  };
};

const infoStep: Step = {
  step: "information-step",
  component: (dispatch, state, formik) => {
    if (!state || !dispatch || !formik)
      throw new Error("Error: state, dispatch or formik not found");

    const { draftUUID } = state.onboardingData;
    const { type } = state.infoStep;

    const getNextStepAndSubStep = () => {
      let nextStep = 1;
      let nextSubStep = 1;

      const { gender, activities } = formik.values;

      const isFirstSubstepCompleted = gender !== "";
      const isFourSubstepCompleted = activities !== "";

      if (isFirstSubstepCompleted) {
        nextStep = 3;
        nextSubStep = 2;
      }

      if (isFourSubstepCompleted) {
        nextSubStep = 5;
      }

      return { nextStep, nextSubStep };
    };

    // Continue
    const onPrimaryClick = () => {
      const email = formik?.values?.email;
      if (email) {
        Sentry.setUser({ email });
      }

      try {
        const { nextStep, nextSubStep } = getNextStepAndSubStep();
        dispatch(onUpdateStep(nextStep));
        dispatch(onUpdateSubStep(nextSubStep));

        if (type === "PENDING_REGISTRY_REMOTE") {
          dispatch(onUpdateReachedStep(nextStep));
          dispatch(onUpdateCompletedStep(nextStep - 1));
        }

        logAnalyticsAndHotjarEvent("onboarding_resumed");
      } catch (error) {
        console.log(error);
        showToastMessage(genericToastifyError, {
          containerId: "informationAlert",
          type: "error",
        });
      }
    };

    // Restart or back
    const onSecondaryClick = async () => {
      try {
        if (
          type === "PENDING_REGISTRY_REMOTE" ||
          type === "PENDING_REGISTRY_LOCAL"
        ) {
          const { cuit, email, referredBool, referrer, identityDocument } =
            formik.values;
          const { token, signatureRequestId } = state.authentication;

          //Delete dniImages and livenessValidation documents
          const db = getFirestore();
          const docFilesRef = doc(
            db,
            "dniImagesSignatory",
            `${draftUUID}-${email}`
          );
          const docLivenessRef = doc(
            db,
            "livenessValidationSignatory",
            `${draftUUID}-${email}`
          );

          await deleteDoc(docFilesRef);
          await deleteDoc(docLivenessRef);

          dispatch({ type: "reset-store" });

          if (type === "PENDING_REGISTRY_REMOTE") {
            formik.resetForm({
              values: {
                cuit,
                email,
                referrer,
                referredBool,
                identityDocument,
              },
            });

            dispatch(onUpdateDraftUUID(draftUUID));
            dispatch(updateSignatureRequestId(signatureRequestId));
            dispatch(updateToken(token));
            dispatch(onUpdateCompletedStep(2));
            dispatch(onUpdateStep(3));
            dispatch(onUpdateReachedStep(3));
          } else if (type === "PENDING_REGISTRY_LOCAL") {
            formik.resetForm();
            dispatch(onUpdateStep(1));
            dispatch(onUpdateReachedStep(1));
          }
          logAnalyticsAndHotjarEvent("onboarding_restart");
        }
      } catch (error) {
        showToastMessage(genericToastifyError, {
          containerId: "informationAlert",
          type: "error",
        });
      }
    };

    const title = "Tenés un registro sin terminar";
    const subtitle = "¿Deseás retomar desde donde lo dejaste?";
    const primaryButtonText = "Retomar registro";
    const secondaryButtonText = "Empezar de nuevo";

    const primarybutton = {
      text: primaryButtonText,
      onClick: onPrimaryClick,
    };
    const secondarybutton = {
      text: secondaryButtonText,
      onClick: onSecondaryClick,
    };

    return (
      <InteractiveInformation
        primaryButton={primarybutton}
        secondaryButton={secondarybutton}
        title={title}
        subtitle={subtitle}
      />
    );
  },
  hideLayoutButtons: true,
  hideStepper: true,
};

const onboardingSignatory = async (): Promise<Template> => {
  const params = new URLSearchParams(document.location.search);
  const externalCode = params.get("externalCode");

  try {
    if (!externalCode) throw new Error("External code not found");

    const onboardingJuridicalTemplate = await onboardingJuridical();

    const [activities, countries] = await Promise.all([
      getActivities(),
      getCountries(),
    ]);

    const activitiesOptions: Option[] = (
      activities as PitbullActivityPhysical[]
    )?.map(({ name }) => {
      return { value: name, label: name };
    });

    const countriesOptions: Option[] = countries?.map(({ name }) => {
      return { value: name, label: name };
    });

    const countriesTyped = countries as PitbullCountry[];

    const provinces = await getProvincesByIso();

    const getCountryIsoAlpha2 = (country: string) => {
      return countriesTyped.find(
        (countryTyped) => countryTyped.name === country
      )?.isoAlpha2;
    };

    const getProvincesFilteredByCountry = (country = "Argentina") => {
      const iso = getCountryIsoAlpha2(country);
      const filteredProvince = provinces.filter((prov) => {
        if (prov.countryIsoAlpha2 === iso) {
          return prov;
        }
      });
      return filteredProvince;
    };

    const { favicon, formTitle, logo } = getCompanyData();

    const onboardingSteps = [
      stepOne,
      stepTwo,
      stepThree(
        countries,
        countriesOptions,
        activitiesOptions,
        getProvincesFilteredByCountry,
        provinces
      ),
      infoStep,
    ];

    const businessPreconfirmationStep = ({
      values,
    }: {
      values: FormikValues;
    }): PreconfirmationStep => {
      let businessPreconfirmationFields: PreconfirmationField[] = [];
      const stepTwoPJ: StepTwoPJ = values.onboardingPJ.find(
        (step: StepTwoPJ) => step.businessInformation
      );

      const businessInformationStep = onboardingJuridicalTemplate?.steps?.find(
        (step) => step.step === 5
      );

      if (businessInformationStep) {
        const businessInformationFields = getOnboardingFields([
          businessInformationStep,
        ]);

        const businessInformationFieldsNames =
          getPreconfirmationStepFieldsByName([
            {
              initialValue: "",
              type: "cuit",
              name: "businessInformation.cuit",
              label: "CUIT de la empresa",
              validation: cuitRequired,
              preconfirmationLabel: "CUIT/CUIL",
              formatInPreConfirmation: (value) => asCuit(value),
            },
            ...businessInformationFields,
          ]);

        const businessInformationFieldsValues = getBusinessInformationValues(
          stepTwoPJ.businessInformation
        );

        const fieldsNamesInBusinessPreconfirmationStep = [
          "businessInformation.legalName",
          "businessInformation.cuit",
          "entityAddress.fullAddress",
          "entityAddress.floor",
          "entityAddress.apartment",
          "entityAddress.zipCode",
          "entityAddress.municipality",
          "entityAddress.state",
          "businessInformation.corporateType",
          "businessInformation.activities",
          "businessInformation.registrationCountry",
          "businessInformation.registrationProvinceText",
          "businessInformation.registrationDate",
          "businessInformation.registrationNumber",
          "businessInformation.registrationAuthority",
          "businessInformation.isPublicEntity",
          "businessInformation.isInEconomicGroup",
          "businessInformation.bankAccounts",
        ];

        businessPreconfirmationFields = [
          ...fieldsNamesInBusinessPreconfirmationStep.map((field) => {
            return {
              ...businessInformationFieldsNames[field],
              values: businessInformationFieldsValues,
              valueGetter: (values: FormikValues) => values[field],
            };
          }),
        ];
      }

      return {
        name: "Datos de la entidad",
        columns: 4,
        fields: businessPreconfirmationFields,
      };
    };

    const getBusinessInformationValues = (
      businessInformation: StepTwoPJ["businessInformation"]
    ) => {
      const {
        legalName,
        cuit,
        addresses,
        corporateType,
        activities,
        registrationCountry,
        registrationProvince,
        registrationDate,
        registrationNumber,
        registrationAuthority,
        isPublicEntity,
        isInEconomicGroup,
        bankAccounts,
      } = businessInformation;

      const address = addresses[0];

      return {
        "businessInformation.legalName": legalName,
        "businessInformation.cuit": cuit,
        "entityAddress.fullAddress": `${address.street} ${address.number}`,
        "entityAddress.floor": address.floor ?? "-",
        "entityAddress.apartment": address.apartment ?? "-",
        "entityAddress.zipCode": address.zipCode,
        "entityAddress.municipality": address.city,
        "entityAddress.state": address.state,
        "businessInformation.corporateType": corporateType,
        "businessInformation.activities": activities,
        "businessInformation.registrationCountry": registrationCountry,
        "businessInformation.registrationProvinceText": registrationProvince,
        "businessInformation.registrationDate":
          parseBirthDate(registrationDate),
        "businessInformation.registrationNumber": registrationNumber,
        "businessInformation.registrationAuthority": parseRegistrationAuthority(
          registrationAuthority
        ),
        "businessInformation.isPublicEntity": isPublicEntity ? "Sí" : "No",
        "businessInformation.isInEconomicGroup": isInEconomicGroup
          ? "Sí"
          : "No",
        "businessInformation.bankAccounts":
          bankAccounts && bankAccounts?.length > 0 ? "Sí" : "No",
      };
    };

    const onboardingFields = getOnboardingFields(onboardingSteps);
    const formattedOnboardingFields =
      getPreconfirmationStepFieldsByName(onboardingFields);

    const formattedOnboardingFieldsNames = Object.keys(
      formattedOnboardingFields
    );

    const signatoryPreconfirmationStep: PreconfirmationStep = {
      name: "Datos del firmante",
      columns: 4,
      fields: [
        ...formattedOnboardingFieldsNames.map(
          (field) => formattedOnboardingFields[field]
        ),
      ],
      editStep: {
        step: 3,
        substep: 4,
      },
    };

    const getPreconfirmationSteps = ({
      values,
    }: {
      values?: FormikValues | null;
    }): PreconfirmationStep[] => {
      if (!values) throw new Error("Error: values not found");

      return [
        businessPreconfirmationStep({ values }),
        signatoryPreconfirmationStep,
      ];
    };

    const getPreconfirmationTerms = ({
      values,
    }: {
      values?: FormikValues | null;
    }) => {
      if (!values) throw new Error("Error: values not found");

      const stepSix: StepSixPJ = values.onboardingPJ.find(
        (step: StepSixPJ) => step.acceptedTermsAndConditions
      );
      const acceptedOptionalTermsByRepresentative =
        stepSix.acceptedOptionalTermsAndConditions;

      const onboardingTerms = getCompanyData().termsAndConditions;
      const signatoryTerms = onboardingTerms.filter(({ required }) =>
        !required ? acceptedOptionalTermsByRepresentative : true
      );

      return signatoryTerms.map((term) => ({
        ...term,
        initialValue: false,
        required: term.required || acceptedOptionalTermsByRepresentative,
        validation:
          term.required || acceptedOptionalTermsByRepresentative
            ? checkboxRequired
            : checkbox,
      }));
    };

    return {
      onboardingType: "SIGNATORY",
      formTitle,
      logo,
      icon: bodyIcon,
      favicon,
      steps: onboardingSteps,
      preconfirmation: (values) => ({
        steps: getPreconfirmationSteps(values),
        checkboxes: getPreconfirmationTerms(values),
      }),
    };
  } catch (error) {
    throw new Error(error as string);
  }
};
export default onboardingSignatory;
