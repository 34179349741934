import styles from "./Stepper.module.scss";
import Step from "./Step";
import { Step as StepType } from "../../types";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  onUpdateStep,
  onUpdateSubStep,
} from "../../reducers/onboardingDataReducer";

const Stepper = () => {
  const { container } = styles;
  const dispatch = useAppDispatch();

  const {
    stepData: { completedSteps, reachedSteps, step },
  } = useAppSelector((state) => state.onboardingData);

  const getStatusOfStep = (findStep: number) => {
    const finded = completedSteps?.find((step) => step === findStep);
    return finded ? "completed" : "disabled";
  };

  const template = useAppSelector((state) => state.activeTemplate);

  const numericTemplateSteps = template?.steps?.filter(
    ({ step: templateStep }) => typeof templateStep === "number"
  );

  const handleStepClick = (
    step: string | number,
    substeps?: StepType[],
    disableBackNavigation?: boolean
  ) => {
    if (reachedSteps?.includes(Number(step)) && !disableBackNavigation) {
      dispatch(onUpdateStep(Number(step)));

      if (substeps) dispatch(onUpdateSubStep(1));
    }
  };

  return (
    <div className={container}>
      <div>
        {numericTemplateSteps?.map(
          (
            {
              step: templateStep,
              name,
              titleOfStep,
              disableBackNavigation,
              subSteps,
            },
            index
          ) => (
            <button
              key={templateStep}
              onClick={() =>
                handleStepClick(templateStep, subSteps, disableBackNavigation)
              }
              disabled={
                !(
                  reachedSteps?.includes(Number(templateStep)) &&
                  !disableBackNavigation
                )
              }
            >
              <Step
                currentStep={
                  Number(templateStep) === step
                    ? "active"
                    : getStatusOfStep(Number(templateStep))
                }
                currentStepWasCompleted={
                  getStatusOfStep(Number(templateStep)) === "completed"
                }
                isFirstStep={index === 0}
                isLastStep={index === numericTemplateSteps.length - 1}
                subtitle={titleOfStep ?? (name || "")}
                title={String(templateStep)}
                nextStepStatus={getStatusOfStep(Number(templateStep) + 1)}
                previousStepStatus={getStatusOfStep(Number(templateStep) - 1)}
                disabledBackNavigation={disableBackNavigation}
              />
            </button>
          )
        )}
      </div>
    </div>
  );
};

export default Stepper;
