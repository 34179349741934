import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface InitialProps {
  activeIndex: number | null;
  isEditing?: boolean;
}

const initialState: InitialProps = {
  activeIndex: 1,
  isEditing: false,
};

const collectionSlice = createSlice({
  name: "collectionData",
  initialState,
  reducers: {
    onUpdateActiveIndex: (state, action: PayloadAction<number | null>) => {
      state.activeIndex = action.payload;
    },
    onUpdateIsEditing: (state, action: PayloadAction<boolean>) => {
      state.isEditing = action.payload;
    },
  },
});

export const { onUpdateActiveIndex, onUpdateIsEditing } =
  collectionSlice.actions;

export default collectionSlice.reducer;
