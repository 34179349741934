import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  isEditingForm: false,
};

const preconfirmationReducer = createSlice({
  name: "preconfirmation",
  initialState,
  reducers: {
    onUpdatePreconfirmation: (state, action: PayloadAction<boolean>) => {
      state.isEditingForm = action.payload;
    },
  },
});

export const { onUpdatePreconfirmation } = preconfirmationReducer.actions;

export default preconfirmationReducer.reducer;
