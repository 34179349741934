import {
  ObjectSchema,
  Maybe,
  AnyObject,
  AnySchema,
  isSchema,
  object,
} from "yup";
import { BasicStep, Conditional, Field, Step } from "../../../types";
import { FormikHelpers, FormikValues } from "formik";
import { isEqual } from "lodash";
import { RootState } from "../../../app/store";

// Flatten step fields validation schema
// Generate nested Yup schema
export const generateYupSchema = (
  fields: Field[] | undefined,
  state?: RootState
): ObjectSchema<Maybe<AnyObject>> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const schemaFields: { [key: string]: any } = {};

  fields?.forEach(({ name, validation, conditionalValidation }) => {
    const path = name.split(".");
    let currentLevel = schemaFields;

    path.forEach((key, index) => {
      if (!currentLevel[key]) {
        if (index === path.length - 1) {
          if (conditionalValidation && state)
            currentLevel[key] = conditionalValidation(state);
          else currentLevel[key] = validation;
        } else {
          currentLevel[key] = {};
        }
      }
      currentLevel = currentLevel[key];
    });
  });

  const buildYupObject = (obj: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  }): { [key: string]: AnySchema } => {
    const yupShape: { [key: string]: AnySchema } = {};
    for (const key in obj) {
      if (isSchema(obj[key])) {
        yupShape[key] = obj[key] as AnySchema;
      } else {
        yupShape[key] = object().shape(buildYupObject(obj[key]));
      }
    }
    return yupShape;
  };

  return object().shape(buildYupObject(schemaFields));
};

export const isEmptyObject = (obj: object): boolean => {
  return Object.keys(obj).length === 0;
};

export const getRequiredFieldNames = (
  fields: Field[],
  state: RootState,
  values: FormikValues
) =>
  fields
    .filter(
      ({
        validation,
        conditionalValidation,
        conditional,
        allConditionalsTrue,
      }) => {
        const requiredSchema = conditionalValidation
          ? isSchemaRequired(conditionalValidation(state))
          : isSchemaRequired(validation);

        return (
          requiredSchema &&
          (!conditional ||
            getIsConditionallyTrue(values, conditional, allConditionalsTrue))
        );
      }
    )
    .map(({ name }) => name);

export const getOptionalFieldNames = (
  fields: Field[],
  state: RootState,
  values: FormikValues
) =>
  fields
    .filter(
      ({
        validation,
        conditionalValidation,
        conditional,
        allConditionalsTrue,
      }) => {
        const notRequiredSchema = conditionalValidation
          ? !isSchemaRequired(conditionalValidation(state))
          : !isSchemaRequired(validation);

        return (
          notRequiredSchema &&
          (!conditional ||
            getIsConditionallyTrue(values, conditional, allConditionalsTrue))
        );
      }
    )
    .map(({ name }) => name);

export const isSchemaRequired = (schema?: AnySchema): boolean => {
  return !!(
    (
      schema &&
      (schema.describe().tests.some((test) => test.name === "required") ||
        !schema.describe().optional)
    ) //When schema is 'mixed' the test 'required' doesn't exist
  );
};
// Get all keys from an object
export const getKeys = (
  obj: Record<string, unknown>,
  prefix = ""
): string[] => {
  return Object.entries(obj).reduce((res: string[], [key, value]) => {
    const newKey = prefix ? `${prefix}.${key}` : key;
    if (typeof value === "object" && value !== null && !Array.isArray(value)) {
      res = res.concat(getKeys(value as Record<string, unknown>, newKey));
    } else {
      res.push(newKey);
    }
    return res;
  }, []);
};

// Get value even if name is nested
export const getValue = (values: FormikValues, name: string) => {
  const value = name.split(".").reduce((acc, part) => acc && acc[part], values);

  // An empty array is a truthy value, so we return an empty string instead
  if (Array.isArray(value) && value.length === 0) {
    return "";
  }

  return value;
};

export const currentIsSubstep = (currentStep: Step | undefined) => {
  return !!currentStep?.subSteps;
};

// Object is empty
export const isObjectEmpty = (obj: Record<string, unknown>): boolean => {
  return Object.keys(obj).length === 0;
};

export const setNestedValue = (
  obj: FormikValues,
  path: string,
  value: unknown
) => {
  const keys = path.split(".");
  let current = obj;

  for (let i = 0; i < keys.length - 1; i++) {
    const key = keys[i];
    if (!current[key]) {
      current[key] = {};
    }
    current = current[key];
  }

  current[keys[keys.length - 1]] = value;
};

export const mergeDeep = (target: AnyObject, source: AnyObject): AnyObject => {
  const output = { ...target };
  for (const key in source) {
    const sourceValue = source[key];
    const targetValue = target[key];

    // Check if the value is a plain object
    if (sourceValue && isPlainObject(sourceValue)) {
      if (isPlainObject(targetValue)) {
        output[key] = mergeDeep(targetValue, sourceValue);
      } else {
        output[key] = mergeDeep({}, sourceValue);
      }
    } else {
      // Handle non-plain objects like File
      output[key] = sourceValue;
    }
  }
  return output;
};

// Helper to check for plain objects (ignoring instances like File, Blob, etc.)
const isPlainObject = (value: unknown): boolean => {
  return (
    typeof value === "object" &&
    value !== null &&
    Object.getPrototypeOf(value) === Object.prototype
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isObject = (item: any): item is AnyObject => {
  return item && typeof item === "object" && !Array.isArray(item);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isArray = (item: any): item is Array<any> =>
  item && Array.isArray(item);

// Reorder array to have defined elements first and undefined elements last
const reorderArray = (arr: Array<unknown>) => {
  // Filter out nullish elements
  const definedElements = arr.filter(
    (element) => element !== null && element !== undefined
  );

  // Determine the number of undefined elements to add back
  const nullCount = arr.length - definedElements.length;

  // Create a new array with the defined elements followed by the undefined elements
  const result = [...definedElements, ...Array(nullCount).fill(undefined)];

  return result;
};

// Check if an array needs reordering
const needsReorder = (arr: Array<unknown>) => {
  // Create a reordered version of the array
  const reorderedArray = reorderArray(arr);

  // Check if the input array is the same as the reordered array using deep equality
  for (let i = 0; i < arr.length; i++) {
    if (!isEqual(arr[i], reorderedArray[i])) {
      return true; // The array needs reordering
    }
  }
  return false; // The array does not need reordering
};

// Used to reorder fields that are grouped together to be displayed without empty spaces
export const reorderFields = (
  currentStepData: BasicStep,
  values: FormikValues,
  setValues: FormikHelpers<FormikValues>["setValues"]
) => {
  const fields = currentStepData?.fields;
  const haveReorderGroups = fields?.some((field) => field.groupReorder);

  // If the current step has reorder groups
  if (fields && haveReorderGroups) {
    // Groups of fields with same groupReorder name
    const reorderGroups = fields
      .filter((field) => field.groupReorder)
      .reduce((acc, field) => {
        const reorderFieldName = field.groupReorder as string;
        if (!acc.includes(reorderFieldName)) {
          acc.push(reorderFieldName);
        }
        return acc;
      }, [] as string[]);

    // Iterate each group
    reorderGroups.forEach((reorderFieldName) => {
      // Fields that belong to the current group
      const reorderGroupFields = fields.filter(
        (field) => field.groupReorder === reorderFieldName
      );

      // Values of the fields that belong to the current group
      const reorderGroupValues = reorderGroupFields.map((field) =>
        getValue(values, field.name)
      );

      // Check if the group needs reordering
      if (needsReorder(reorderGroupValues)) {
        // Reordered values
        const reorderedGroupValues = reorderArray(reorderGroupValues);

        // Create an object with the reordered values
        // Having in account the nested keys
        const reorderedValues = reorderGroupFields.reduce(
          (acc, field, index) => {
            setNestedValue(acc, field.name, reorderedGroupValues[index]);

            return acc;
          },
          {}
        );

        // Merge the reordered values with the current formik values
        const mergedValues = mergeDeep(values, reorderedValues);

        // Set the values to formik
        setValues(mergedValues);
      }
    });
  }
};

export const getNestedValue = (
  obj: Record<string, unknown>,
  name: string | string[]
): unknown => {
  let path;

  if (Array.isArray(name)) {
    path = name;
  } else {
    path = name ? name?.split(".") : [];
  }

  if (path.length === 0) return obj;
  const [head, ...tail] = path;
  if (typeof obj[head] === "object" && obj[head] !== null) {
    return getNestedValue(obj[head] as Record<string, unknown>, tail.join("."));
  }
  return obj[head];
};

export const getIsConditionallyTrue = (
  values: FormikValues,
  conditional: Conditional | Conditional[],
  allConditionalsTrue?: boolean
) => {
  const checkConditional = (cond: Conditional) => {
    const conditionalValue = getNestedValue(values, cond.name) as string;
    return Array.isArray(cond.value)
      ? cond.value.includes(conditionalValue)
      : cond.value === conditionalValue;
  };

  if (Array.isArray(conditional)) {
    return allConditionalsTrue
      ? conditional.every(checkConditional)
      : conditional.some(checkConditional);
    // If the conditional field is shown by a single field
  } else {
    // If the conditional field has the correct value return true
    if (conditional.name) {
      const conditionalValue = getNestedValue(
        values,
        conditional.name
      ) as string;

      return Array.isArray(conditional.value)
        ? conditional.value.includes(conditionalValue)
        : conditional.value === conditionalValue;
    }
  }
};
