import React from "react";
import * as Sentry from "@sentry/react";
import { FaceLivenessDetector } from "@aws-amplify/ui-react-liveness";
import lottieJson from "./success-lottie.json";
import { getErrorData, livenessTexts, saveLivenessData } from "./utils/utils";
import { useAppDispatch } from "../../app/hooks";
import { openModal } from "../../reducers/modalReducer";
import { LoadingCircle } from "@almafintech/react-components/LoadingCircle";
import { addOneValidation } from "../../reducers/validationsReducer";
import { Player } from "@lottiefiles/react-lottie-player";
import SuccessCard from "../SuccessCard/SuccessCard";
import { LivenessData } from "./interfaces/types";
import {
  detectFaceLiveness,
  sendBusinessRepresentativeDocument,
  sendIndividualDocument,
  verifyFacialRecognition,
} from "../../connectors/connectors";
import { onUpdateLivenessState } from "../../reducers/livenessReducer";

import errorIcon from "../../assets/images/icons/alert/error-cross.svg";
import successIcon from "../../assets/images/icons/alert/success.svg";
import successQRIcon from "../../assets/images/close-window.svg";
import warningIcon from "../../assets/images/icons/alert/warning.svg";
import validExample from "../../assets/images/liveness/valid-example.svg";
import invalidExample from "../../assets/images/liveness/invalid-example.svg";

import styles from "./LivenessValidationCard.module.scss";

interface LivenessProps {
  uuid: string;
  sessionId: string;
  isLoadingData: boolean;
  livenessData: LivenessData;
  livenessState: string | null;
  reachedAttemptsLimit: boolean;
  error: string | null;
  handleError: (errorState?: string) => void;
  isExternalLink: boolean;
  continueWithQR: boolean;
  isMobile: boolean;
  playerRef: React.RefObject<Player>;
  isJuridicalOnboarding?: boolean;
  cuil?: string;
}

const Liveness = ({
  uuid,
  sessionId,
  isLoadingData,
  livenessData,
  livenessState,
  reachedAttemptsLimit,
  error,
  handleError,
  isExternalLink,
  continueWithQR,
  isMobile,
  playerRef,
  isJuridicalOnboarding,
  cuil,
}: LivenessProps) => {
  const {
    livenessContainer,
    livenessMobileContainer,
    livenessExternalLinkContainer,
    initialContainer,
    examplesContainer,
    validContainer,
    invalidContainer,
    successQR,
    loading,
    error: errorStyle,
    warning,
    contentCenter,
    icon,
  } = styles;

  const dispatch = useAppDispatch();

  const { dni, gender, scannedQR, isValid } = livenessData;

  const handleAnalysisComplete = async () => {
    if (sessionId) {
      try {
        const livenessResult = await detectFaceLiveness({ sessionId });
        const { status, validations } = livenessResult.data;
        const { referenceImage } = validations[0].information;

        // Endpoint receives either "F" or "M", there's no "X"
        const renaperGender = gender === "F" ? gender : "M";

        if (status === "OK") {
          const renaperVerificationData = await verifyFacialRecognition({
            image: referenceImage,
            gender: renaperGender,
            number: dni,
          });

          const { validations: renaperValidations } =
            renaperVerificationData.data;
          const { status: renaperStatus } = renaperValidations[0].information;

          if (renaperStatus === "HIT") {
            const url = `data:image/jpeg;base64,${referenceImage}`;
            const response = await fetch(url);
            const blob = await response.blob();
            const selfie = new File([blob], "selfie.jpg", {
              type: "image/jpeg",
            });
            if (!cuil) return handleError();
            
            if (isJuridicalOnboarding) {
              await sendBusinessRepresentativeDocument({
                file: selfie,
                documentType: "SELFIE",
                draftUUID: uuid,
                cuit: cuil,
              });
            } else {
              await sendIndividualDocument({
                file: selfie,
                documentType: "SELFIE",
                draftUUID: uuid,
                cuit: cuil,
              });
            }

            //FACIAL_RECOGNITION
            dispatch(
              addOneValidation({
                name: renaperValidations[0].name,
                externalCode: renaperValidations[0].externalCode,
              })
            );

            //FACE_LIVENESS_DETECTION
            dispatch(
              addOneValidation({
                name: validations[0].name,
                externalCode: validations[0].externalCode,
              })
            );

            saveLivenessData({
              ...livenessData,
              isValid: true,
              uuid,
              facialRecognition: renaperValidations[0].externalCode,
              faceLivenessDetection: validations[0].externalCode,
            });
          } else {
            handleError();
          }
        } else {
          // status is "CHECK" or "FAILED"
          handleError();
        }
      } catch (error) {
        console.log(error);
        console.log("Error in liveness validation");
        handleError();
      }
    }
  };

  const handleRequirementsClick = () => {
    dispatch(openModal({ name: "modal-minimum-requirements" }));
  };

  const { errorType, errorMessage } = getErrorData({
    error,
    reachedAttemptsLimit,
    handleRequirementsClick,
  });

  return (
    <div
      className={`${livenessContainer}   ${
        isExternalLink ? livenessExternalLinkContainer : ""
      } ${isMobile ? livenessMobileContainer : ""}`}
    >
      {isLoadingData && !livenessState && (
        <div className={loading}>
          <LoadingCircle width="50" />
        </div>
      )}
      {livenessState === "INITIAL" && (
        <section className={initialContainer}>
          <div>
            <h3>A continuación debés validar tu identidad</h3>
            <span>
              Acercate a un lugar con buena iluminación, sacate cualquier
              accesorio facial y subí el brillo de tu dispositivo.
            </span>
          </div>
          <div className={examplesContainer}>
            <div className={validContainer}>
              <img src={successIcon} alt="successIcon" className={icon} />
              <img src={validExample} alt="Ejemplo válido" />
            </div>
            <div className={invalidContainer}>
              <img src={errorIcon} alt="errorIcon" className={icon} />
              <img src={invalidExample} alt="Ejemplo inválido" />
            </div>
          </div>
        </section>
      )}
      {sessionId && livenessState === "DETECTOR" && (
        <FaceLivenessDetector
          sessionId={sessionId}
          region="us-east-1"
          onAnalysisComplete={handleAnalysisComplete}
          disableStartScreen={true}
          onError={(error) => {
            console.log(error);
            Sentry.captureException(error);
            handleError(error.state);
          }}
          displayText={livenessTexts}
        />
      )}
      {livenessState === "CAMERA_ACCESS_REQUIRED" && (
        <div className={`${contentCenter} ${warning}`}>
          <img src={warningIcon} alt="icon" />
          <span>Conectá una cámara para validar tu identidad</span>
        </div>
      )}
      {isValid && !livenessState && (
        <div className={`${contentCenter}`}>
          <Player
            onEvent={(event) => {
              if (event === "complete" || event === "error")
                dispatch(onUpdateLivenessState("VALIDATED"));
            }}
            ref={playerRef}
            src={lottieJson}
            loop={false}
            style={{
              width: "75%",
              padding: "3rem",
            }}
          />
        </div>
      )}
      {livenessState === "VALIDATED" &&
        (continueWithQR ? (
          <div className={successQR}>
            <img src={successQRIcon} alt="successQRIcon" />
            <h2>
              ¡Listo! Podés cerrar esta página y seguir en tu computadora.
            </h2>
          </div>
        ) : (
          <SuccessCard
            title="¡Validaste tu identidad!"
            message={
              isExternalLink ? "Podés cerrar esta página" : "Podés continuar"
            }
          />
        ))}
      {livenessState === "NOT_VALIDATED" && (
        <div
          className={`${contentCenter} ${
            errorType === "ERROR" ? errorStyle : warning
          } ${scannedQR ? "mb-5" : ""}`}
        >
          <img
            src={errorType === "ERROR" ? errorIcon : warningIcon}
            alt="icon"
          />
          {errorMessage}
        </div>
      )}
    </div>
  );
};

export default Liveness;
