import * as Yup from "yup";
import { YupDefaultProps, YupSelectProps, YupStringProps } from "./types";
import { string, array, boolean, ref } from "yup";
import { Option } from "../types";

const fieldRequiredMessage = "Este campo es obligatorio.";
const emailFormatMessage = "El formato debe ser direccion@mail.com";
const specialCharactersMessage = "No debe contener caracteres especiales.";
const maxMessage = (max: number) => `Debe tener menos de ${max} caracteres.`;
const tokenMessage = "Debe tener 7 caracteres.";
const percentageRangeMessage = "No debe superar el 100.";

export const yupString = ({
  min,
  max,
  required,
  minMessage,
  maxMessage,
  requiredMessage,
  regex,
  regexMessage,
}: YupStringProps) => {
  let schema = Yup.string();
  if (min) schema = schema.min(min, minMessage);
  if (max) schema = schema.max(max, maxMessage);
  if (regex) schema = schema.matches(regex, regexMessage);
  if (required) schema = schema.required(requiredMessage);
  return schema;
};

export const yupSelect = ({
  type,
  options,
  optionsMessage,
  required,
  requiredMessage,
}: YupSelectProps) => {
  let schema;

  const optionsSchema = Yup.string().oneOf(
    options.map((option) => option.value),
    optionsMessage
  );

  if (type === "single") {
    schema = optionsSchema;
  }

  if (type === "multiple") {
    schema = Yup.array().of(optionsSchema);
  }

  if (required && schema) {
    schema = schema.required(requiredMessage);
  }

  return schema;
};

export const YupNumber = ({
  min,
  max,
  required,
  minMessage,
  maxMessage,
  requiredMessage,
}: YupDefaultProps) => {
  let schema = Yup.number();

  if (min) schema = schema.min(min, minMessage);
  if (max) schema = schema.max(max, maxMessage);
  if (required) schema = schema.required(requiredMessage);
  return schema;
};

export const stringRequired = string().required(fieldRequiredMessage);
export const stringNotRequired = string()

export const percentageRequired = string()
  // Matches a percentage till 100, with optional decimal places
  .matches(/^(100|[1-9]?[0-9])(\.[0-9]{1,2})?$/, percentageRangeMessage)
  .required(fieldRequiredMessage);

export const numberRequired = string()
  .matches(/^[0-9]*$/, "Deben ser solo números.")
  .required(fieldRequiredMessage);

export const smallStringRequired = string()
  .max(50, maxMessage(50))
  .required(fieldRequiredMessage);

export const smallString = string().max(50, maxMessage(50)).nullable();

export const mediumStringRequired = string()
  .max(100, maxMessage(100))
  .required(fieldRequiredMessage);

export const largeStringRequired = string()
  .max(200, maxMessage(200))
  .required(fieldRequiredMessage);

export const noSpecialCharactersRequired = string()
  .matches(/^[a-zA-Z0-9]*$/, specialCharactersMessage)
  .required(fieldRequiredMessage);

export const checkboxRequired = boolean().oneOf(
  [true],
  "Este campo es obligatorio"
);
export const checkbox = boolean().notRequired();

// Personal information
export const emailRequired = string()
  .email(emailFormatMessage)
  .required(fieldRequiredMessage);

export const confirmEmailRequired = string()
  .oneOf([ref("email")], "Los emails no coinciden.")
  .required(fieldRequiredMessage);

export const phone = string()
  .required(fieldRequiredMessage)
  .test("is-valid-phone", fieldRequiredMessage, (value) => {
    if (!value) {
      return false; // Required
    }
    return true;
  })
  .test("is-valid-phone", "Debe tener 12 digitos.", (value) => {
    if (!value) {
      return false; // Required
    }
    // Checks if it starts with "54" and has 12 digits
    if (value.startsWith("54")) {
      return value.length === 12;
    } else return true;
  })
  .test("is-valid-phone", "Debe tener mínimo 9 dígitos.", (value) => {
    if (!value) {
      return false; // If the value is empty, it is not valid
    }
    if (!value.startsWith("54")) {
      return value.length >= 9 && /^\d+$/.test(value);
    }
    return true;
  });

export const dniRequired = string()
  .matches(/^[0-9]{7,8}$/, "Deben ser 7 u 8 números.")
  .required(fieldRequiredMessage);

export const tramitNumberRequired = string()
  .matches(/^[0-9]{11}$/, "Deben ser 11 números.")
  .required(fieldRequiredMessage);

export const cuitRequired = string()
  .matches(/^(20|23|24|27|30|33)/, "Debe comenzar con 20, 23, 24, 27, 30 o 33.")
  .matches(/^[0-9]{11}$/, "Deben ser 11 números.")
  .required(fieldRequiredMessage);

export const cuitSpouse = string()
  .matches(/^(20|23|24|27|30|33)/, "Debe comenzar con 20, 23, 24, 27, 30 o 33.")
  .matches(/^[0-9]{11}$/, "Deben ser 11 números.")
  .notOneOf([ref("cuit")], "Debe ser distinto al tuyo.");

export const shareholderCuitSpouse = string()
  .matches(/^(20|23|24|27|30|33)/, "Debe comenzar con 20, 23, 24, 27, 30 o 33.")
  .matches(/^[0-9]{11}$/, "Deben ser 11 números.")
  .notOneOf([ref("taxId")], "Debe ser distinto al tuyo.");

export const cbuNotRequired = string()
  .matches(/^[0-9]{22}$/, "Deben ser 22 números.")
  .nullable();
export const cbuRequired = cbuNotRequired.required(fieldRequiredMessage);

export const radioOrSingleSelectRequired = (options: Option[]) =>
  string()
    .oneOf(options.map((option) => option.value))
    .required(fieldRequiredMessage);

export const tokenRequired = noSpecialCharactersRequired
  .min(7, tokenMessage)
  .max(7, tokenMessage);

export const zipCodeRequired = string()
  .matches(/^[a-zA-Z0-9\s-]+$/, specialCharactersMessage)
  .max(50, maxMessage(50))
  .required(fieldRequiredMessage);

export const shareholderCuitRequired = (shareholdersCuit: string[]) =>
  cuitRequired.notOneOf(
    shareholdersCuit,
    "No se puede utilizar la misma identificación fiscal para más de un accionista"
  );
export const shareholderNifRequired = (shareholdersCuit: string[]) =>
  stringRequired.notOneOf(
    shareholdersCuit,
    "No se puede utilizar la misma identificación fiscal para más de un accionista"
  );

export const signatoryEmailRequired = (signatoriesEmail: string[]) =>
  string()
    .email(emailFormatMessage)
    .required(fieldRequiredMessage)
    .notOneOf(
      signatoriesEmail,
      "No se puede utilizar el mismo mail para más de un firmante."
    );

export const multipleSelectRequired = (options: Option[]) => {
  const optionsSchema = Yup.string().oneOf(
    options.map((option) => option.value),
    "Debe seleccionar al menos una opción."
  );

  return array()
    .min(1, "Debe seleccionar al menos una opción.")
    .of(optionsSchema)
    .required(fieldRequiredMessage);
};

const createFileSchema = (
  supportedFormats: string[],
  maxFileSize: number,
  isRequired: boolean
) => {
  const maxFileSizeConvertedToBytes = maxFileSize * 1024 * 1024; // Convert to bytes
  let schema = Yup.mixed()
    .test("fileFormat", "FORMAT-ERROR", (value) => {
      const file = value as File;
      if (!value || !file?.type) return false;
      return supportedFormats.includes(file.type);
    })
    .test("fileSize", "SIZE-ERROR", (value) => {
      const file = value as File;
      if (!value || !file?.size) return false;
      return (
        file.size < maxFileSizeConvertedToBytes || typeof value === "number"
      );
    });

  if (isRequired) {
    schema = schema.required(fieldRequiredMessage);
  }

  return schema;
};

export const fileNotRequired = (
  supportedFormats: string[],
  maxFileSize: number
) => createFileSchema(supportedFormats, maxFileSize, false);

export const filesNotRequired = (
  supportedFormats: string[],
  maxFileSize: number
) => Yup.array().of(fileNotRequired(supportedFormats, maxFileSize)).nullable();

export const fileRequired = (supportedFormats: string[], maxFileSize: number) =>
  createFileSchema(supportedFormats, maxFileSize, true);

export const filesRequired = (
  supportedFormats: string[],
  maxFileSize: number
) =>
  Yup.array()
    .of(fileRequired(supportedFormats, maxFileSize))
    .required(fieldRequiredMessage);

// Address is an object of type InputAddressProps.onValueChange data
// const addressShape = {
//   formattedAddress: Yup.object().shape({
//     street: string(),
//     streetNumber: string(),
//     city: string(),
//     state: string(),
//     country: string(),
//     postalCode: string(),
//   }),
//   fullData: Yup.object().shape({
//     prediction: Yup.object(),
//     place: Yup.object(),
//   }),
// };

// export const addressRequired = Yup.object()
//   .shape(addressShape)
//   .required(fieldRequiredMessage);
