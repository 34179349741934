import { useEffect, useMemo, useState } from "react";

// DEPENDENCIES
import { useFormik } from "formik";

// UTILS
import {
  generateYupSchema,
  getKeys,
  getRequiredFieldNames,
  isEmptyObject,
} from "./utils";
import { onUpdatePrimaryButtonModal } from "../../reducers/modalReducer";
import { onUpdateIsEditing } from "../../reducers/collectionReducer";

// HOOKS
import useWindowWidth from "../../hooks/useWindowWidth";
import { useAppDispatch, useAppSelector } from "../../app/hooks";

// TYPES
import { SecondaryForm } from "../../types";

// COMPONENTS
import { Button } from "@almafintech/react-components/Button";
import Container from "../Container/Container";
import TemplateInputSelector from "./TemplateInputSelector";
import Tabs from "../Tabs/Tabs";
import AlertModal from "../AlertModal/AlertModal";
import { showToastMessage } from "@almafintech/react-components/ToastMessage";

import styles from "../TemplateInterpreter/TemplateInterpreter.module.scss";

const TemplateInterpreterSecondaryForm = ({
  collectionName,
  tabs,
  fields,
  onSave,
  alert,
  successMessage,
  primaryForm,
  title,
  onSuccessCondition,
  onSuccess,
}: SecondaryForm) => {
  const { formContainer, maxHeight, secondaryForm, saveButton } = styles;

  const { setValues: setPrimaryFormValues } = primaryForm || {};

  const dispatch = useAppDispatch();

  const state = useAppSelector((state) => state);
  const { formValues } = state.onboardingData;
  const { activeIndex } = state.collectionData;

  const collection = useMemo(
    () => formValues?.[collectionName] || {},
    [formValues, collectionName]
  );

  const activeData = useMemo(
    () => (activeIndex && collection[activeIndex - 1]) || {},
    [collection, activeIndex]
  );

  const [activeTab, setActiveTab] = useState<string>(
    tabs?.active(activeData) || ""
  );

  const initialsecondaryFormValues = fields.reduce(
    (acc: Record<string, string>, { name, initialValue }) => {
      acc[name] = initialValue;
      return acc;
    },
    {}
  );

  const secondaryFormFieldsValidations = generateYupSchema(fields, state);

  const secondaryFormFormik = useFormik({
    initialValues: initialsecondaryFormValues,
    validationSchema: secondaryFormFieldsValidations,
    onSubmit: (_, { setSubmitting }) => {
      setSubmitting(false);
    },
    // So the validation is triggered on mount to update isValid and disable the button until all the fields are valid
    // If not, the button will be enabled by default
    // The invalid styles of the fields will not be shown until the touched state is true
    validateOnMount: true,
  });

  const {
    values,
    setValues,
    resetForm,
    touched,
    setTouched,
    errors,
    setErrors,
    isSubmitting,
    setSubmitting,
  } = secondaryFormFormik;

  const requiredFieldsNames = useMemo(() => {
    return getRequiredFieldNames(fields, state, values);
  }, [fields, values]);

  const requiredFieldsHaveErrors =
    getKeys(errors).filter((key) => requiredFieldsNames?.includes(key)).length >
    0;

  const reset = () => {
    resetForm();
  };

  const handleActiveTabChange = (tab: string) =>
    tabs?.onChange(tab, setActiveTab, dispatch, state, values);

  const isSaveButtonDisabled =
    Object.keys(touched).length === 0 ||
    requiredFieldsHaveErrors ||
    isSubmitting;

  const dimensions = useWindowWidth();
  const isMobile = dimensions.width < 768;

  const handleSubmit = () => {
    successMessage &&
      showToastMessage(successMessage, {
        containerId: "formAlert",
        type: "success",
      });
    onSave(values, dispatch, state, setPrimaryFormValues, secondaryFormFormik);
    setSubmitting(false);
  };

  useEffect(() => {
    if (!isEmptyObject(activeData)) {
      setValues(activeData, false);
      setTouched({}, false);
      setErrors({});
    }
  }, [activeData]);

  useEffect(() => {
    if (isEmptyObject(values)) reset();
  }, [values]);

  useEffect(() => {
    if (tabs) setActiveTab(tabs.active(activeData));
  }, [activeData]);

  useEffect(() => {
    if (alert)
      dispatch(
        onUpdatePrimaryButtonModal({
          text: alert.primaryButton.text,
          action: () => alert.primaryButton.action(activeTab, dispatch, state),
        })
      );
  }, [activeData, activeTab]);

  useEffect(() => {
    dispatch(onUpdateIsEditing(!isSaveButtonDisabled));
  }, [isSaveButtonDisabled]);

  return (
    <Container className={`flex-col ${secondaryForm}`}>
      {onSuccessCondition && onSuccess && onSuccessCondition(state) ? (
        onSuccess()
      ) : (
        <>
          {activeIndex && title && <h2>{title(activeIndex)}</h2>}
          {tabs && (
            <Tabs
              options={tabs.options}
              active={activeTab}
              onChange={handleActiveTabChange}
              disabled={tabs.disabled ? tabs.disabled(state) : false}
            />
          )}
          {alert && <AlertModal {...alert} />}
          <form className={`${formContainer}  ${tabs ? maxHeight : ""}`}>
            {fields.map((field) => (
              <TemplateInputSelector
                currentStepFields={fields}
                key={field.name}
                field={field}
                formik={secondaryFormFormik}
              />
            ))}
          </form>
          <Button
            text="Guardar"
            type="submit"
            onClick={handleSubmit}
            isLoading={isSubmitting}
            isDisabled={isSaveButtonDisabled}
            fullWidth={isMobile}
            variant={isMobile ? "secondary" : "primary"}
            className={saveButton}
          />
        </>
      )}
    </Container>
  );
};

export default TemplateInterpreterSecondaryForm;
